import { AppBaseApi } from '../api';
import { AxiosResponse } from 'axios';
import { Intercom, IUpgradeActionIntercom } from '../../model/interfaces/intercom';
import { CSVSearchParamRequest } from '../api/pagedListRequest';

export interface ResponseIntercom {
    Data: Intercom[];
    TotalCount: number;
}
export class IntercomsService extends AppBaseApi {
    public getAll(search: any): Promise<AxiosResponse<ResponseIntercom>> {
        return this.axios.post(this.basePath + '/intercoms', search)
    }

    public upgrade(mac: string, upgradeModel: IUpgradeActionIntercom): Promise<AxiosResponse<boolean>> {
        return this.axios.post(this.basePath + `/intercoms/${mac}/upgrade`,
          {Id: upgradeModel.Id, Sip: upgradeModel.Sip, WanMac: upgradeModel.WanMac})
    }

    public openGate(mac: string, note: string): Promise<AxiosResponse<boolean>> {
        return this.axios.post(this.basePath + `/intercoms/${mac}/opengate`, {Reason: note})
    }

    public closeGate(mac: string, note: string): Promise<AxiosResponse<boolean>> {
        return this.axios.post(this.basePath + `/intercoms/${mac}/closegate`, {Reason: note})
    }

    public vendGate(mac: string, note: string): Promise<AxiosResponse<boolean>> {
        return this.axios.post(this.basePath + `/intercoms/${mac}/vendgate`, {Reason: note})
    }

    public bulkUpdate(firmwareId: number, wanMacAddresses: string[]): Promise<AxiosResponse<boolean>> {
        return this.axios.post(this.basePath + `/intercoms/bulk/upgrade`, {Id: firmwareId, WanMac: wanMacAddresses})
    }

    public getDetailByWanMac(intercomWanMac: string): Promise<AxiosResponse<any>> {
        return this.axios.post(this.basePath + `/intercoms/${intercomWanMac}/config`)
    }

    public getCSVReport(csvSearchParam: CSVSearchParamRequest): Promise<AxiosResponse<any>> {
        return this.axios.post(this.basePath + `/intercoms/csvreport`, csvSearchParam);
    }

    public bulkReboot(wanMacAddresses: string[]): Promise<AxiosResponse<boolean>> {
        return this.axios.post(this.basePath + `/intercoms/bulk/reboot`, {WanMac: wanMacAddresses})
    }

    public reboot(wanMac: string) {
        return this.axios.post(this.basePath + `/intercoms/${wanMac}/reboot`)
    }

}
