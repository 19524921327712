import { FunctionComponent } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import styles from './ConfirmDialog.module.scss';

export interface ConfirmDialogProps {
  isOpen: boolean,
  title: string,
  message: string,
  onConfirm: (flag: boolean) => void,
  onCancel: (flag: boolean) => void,
  confirmButtonText: string | undefined;
  cancelButtonText: string | undefined;
}
const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = (props: ConfirmDialogProps) => {
  if (!props.isOpen) return null;

  const closeDialog = () => {
    props.onCancel(false);
  }

  const acceptDialog = () => {
    props.onConfirm(true);
  }

  return (
    <Dialog width={422} title={props.title ?? 'Confirm Dialog'} onClose={closeDialog}>
      <div style={{marginBottom: 20}}>
        {props.message ?? 'Do you agree?'}
      </div>
      <div className={styles.modalActionBar}>
        <button className={styles.actionButtonGray} onClick={closeDialog}>{props.cancelButtonText ?? 'Cancel'}</button>
        <button className={styles.actionMainButton}
                onClick={acceptDialog}
                type='button'>{props.confirmButtonText ?? 'Confirm'}</button>
      </div>

    </Dialog>
  )
}

export default ConfirmDialog;
