import React, { createContext, useState, useContext, useCallback } from 'react';
import ConfirmDialog, { ConfirmDialogProps } from './ConfirmDialog';

interface ConfirmDialogContextType {
  openConfirmDialog: (title: string, message: string, confirmButtonText?: string, cancelButtonText?: string) => Promise<boolean>;
}

const ConfirmDialogContext = createContext<ConfirmDialogContextType | null>(null);

interface ConfirmDialogProviderProps {
  children: React.ReactNode;
}

export const ConfirmDialogProvider: React.FC<ConfirmDialogProviderProps> = ({ children }) => {
  const [dialogState, setDialogState] = useState<ConfirmDialogProps>({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Confirm',
  });

  const openConfirmDialog = useCallback((title: string, message: string, confirmButtonText?: string, cancelButtonText?: string) => {
    return new Promise<boolean>((resolve) => {
      setDialogState({
        isOpen: true,
        title,
        message,
        onConfirm: () => {
          resolve(true);
          setDialogState((prev) => ({ ...prev, isOpen: false }));
        },
        onCancel: () => {
          resolve(false);
          setDialogState((prev) => ({ ...prev, isOpen: false }));
        },
        confirmButtonText,
        cancelButtonText,
      });
    });
  }, []);

  return (
    <ConfirmDialogContext.Provider value={{ openConfirmDialog }}>
      {children}
      <ConfirmDialog {...dialogState} />
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialog = (): ConfirmDialogContextType => {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error('useConfirmDialog must be used within a ConfirmDialogProvider');
  }
  return context;
};
